<template>
  <Layout>
    <h1>Error 404</h1>
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'

export default {
  name: 'NotFound',
  components: {
    Layout
  }
}
</script>
